import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ThreeBackground from '../ThreeBackground';

const Home: React.FC = () => {
  return (
    <Box className="main-content" position="relative" minH="80vh" overflow="hidden" color="white">
      <ThreeBackground />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        textAlign="center"
        zIndex="1"
      >
        <Heading as="h1" size="2xl" mb={4}>
          Unlock Your Potential
        </Heading>
        <Text fontSize="lg" mb={6}>
          Discover how Vexatek's innovative software solutions can
          <br />
          help you achieve your business goals.
        </Text>
        <Button as={Link} to="/contact" colorScheme="teal" size="lg">
          Get Started
        </Button>
      </Box>
    </Box>
  );
};

export default Home;
