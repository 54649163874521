import React from 'react';
import { Box, Heading, Text, SimpleGrid, Image, Stack } from '@chakra-ui/react';

const projects = [
  {
    title: 'Project One',
    description: 'Description for project one.',
    image: 'https://via.placeholder.com/300',
  },
  {
    title: 'Project Two',
    description: 'Description for project two.',
    image: 'https://via.placeholder.com/300',
  },
  // Add more projects as needed
];

const Portfolio: React.FC = () => {
  return (
    <Box p={8} bg="background" color="text">
      <Heading as="h2" size="xl" color="primary" mb={6} textAlign="center">
        Our Portfolio
      </Heading>
      <SimpleGrid columns={[1, 2, 3]} spacing={8}>
        {projects.map((project, index) => (
          <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden" bg="white" color="gray.800" boxShadow="lg">
            <Image src={project.image} alt={project.title} />
            <Box p={4}>
              <Heading as="h3" size="md" color="primary" mb={2}>
                {project.title}
              </Heading>
              <Text>{project.description}</Text>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Portfolio;
