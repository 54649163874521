import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

// Function to create a logo-style texture for cube faces
const createLogoTexture = () => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return new THREE.CanvasTexture(canvas);

  canvas.width = 512; // Larger canvas width
  canvas.height = 512; // Larger canvas height

  const borderColor = '#E2E8F0';
  const lineWidth = 5;

  // Calculate dimensions based on canvas size
  const initialRectHeight = canvas.height * 0.15; // Initial bottom rectangle height
  const verticalRectWidth = canvas.width * 0.15; // Width for vertical rectangles
  const smallRectSize = canvas.width * 0.10; // Size for smallest square
  const fixedDrop = canvas.width * 0.15; // Fixed drop size (15% of the original size)

  context.strokeStyle = borderColor;
  context.lineWidth = lineWidth;
  context.fillStyle = 'transparent';

  // Draw horizontal rectangles aligned to the right
  let width = canvas.width; // Full width for the first rectangle
  for (let i = 0; i < 7; i++) { // Total of 7 rectangles
    const y = canvas.height - (i + 1) * initialRectHeight;
    context.beginPath();
    context.moveTo(canvas.width - width, y);
    context.lineTo(canvas.width, y);
    context.stroke();
    width -= fixedDrop; // Decrease width by fixed amount
  }

  // Draw vertical rectangles aligned to the left
  let height = canvas.height; // Full height for the first rectangle
  for (let i = 0; i < 7; i++) { // Total of 7 rectangles
    const x = i * verticalRectWidth;
    context.beginPath();
    context.moveTo(x, 0);
    context.lineTo(x, height);
    context.stroke();
    height -= fixedDrop; // Decrease height by fixed amount
  }

  // Draw smallest square in the upper right corner
  context.strokeRect(canvas.width - smallRectSize, 0, smallRectSize, smallRectSize);

  // Draw border around the entire canvas
  context.strokeStyle = borderColor;
  context.lineWidth = lineWidth;
  context.strokeRect(0, 0, canvas.width, canvas.height);

  return new THREE.CanvasTexture(canvas);
};

const ThreeBackground: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);
  const fallingCubesRef = useRef<THREE.Mesh[]>([]);
  const textureRef = useRef<THREE.CanvasTexture | null>(null);

  useEffect(() => {
    if (mountRef.current) {
      // Set up scene, camera, and renderer
      const scene = new THREE.Scene();
      scene.background = new THREE.Color('#1A202C'); // Set background color

      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      mountRef.current.appendChild(renderer.domElement);

      // Create a rotating background cube
      const geometry = new THREE.BoxGeometry();
      const texture = createLogoTexture(); // Create texture
      textureRef.current = texture; // Store reference to texture
      const material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.7,
        wireframe: false
      });
      const materials = [material, material, material, material, material, material];
      const backgroundCube = new THREE.Mesh(geometry, materials);
      // scene.add(backgroundCube);

      // Create falling cubes
      const createFallingCube = () => {
        const size = Math.random() * 0.5 + 0.1; // Random size between 0.1 and 0.6
        const cubeGeometry = new THREE.BoxGeometry(size, size, size);
        const cubeMaterial = new THREE.MeshBasicMaterial({
          map: textureRef.current!,
          transparent: true,
          opacity: 0.5
        });
        const fallingCube = new THREE.Mesh(cubeGeometry, cubeMaterial);

        fallingCube.position.set(
          Math.random() * 10 - 5, // Random x position
          Math.random() * 10 + 5, // Start above the screen
          Math.random() * 10 - 5  // Random z position
        );

        // Set random speed and rotation speed
        fallingCube.userData.speed = Math.random() * 0.01 + 0.005; // Slower falling speed
        fallingCube.userData.rotationSpeedX = Math.random() * 0.01 + 0.005; // Rotation speed around X axis
        fallingCube.userData.rotationSpeedY = Math.random() * 0.01 + 0.005; // Rotation speed around Y axis
        fallingCube.userData.rotationSpeedZ = Math.random() * 0.01 + 0.005; // Rotation speed around Z axis

        scene.add(fallingCube);
        fallingCubesRef.current.push(fallingCube);
      };

      // Create multiple falling cubes
      for (let i = 0; i < 50; i++) {
        createFallingCube();
      }

      // Position the camera
      camera.position.z = 5;

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);

        // Rotate the background cube
        backgroundCube.rotation.x += 0.01;
        backgroundCube.rotation.y += 0.01;

        // Update falling cubes
        fallingCubesRef.current.forEach(cube => {
          // Update position
          cube.position.y -= cube.userData.speed;
          if (cube.position.y < -5) {
            cube.position.y = Math.random() * 10 + 5; // Reset to above the screen
            cube.position.x = Math.random() * 10 - 5; // Random x position
            cube.position.z = Math.random() * 10 - 5; // Random z position
          }

          // Update rotation
          cube.rotation.x += cube.userData.rotationSpeedX;
          cube.rotation.y += cube.userData.rotationSpeedY;
          cube.rotation.z += cube.userData.rotationSpeedZ;
        });

        renderer.render(scene, camera);
      };

      animate();

      // Handle resizing
      const handleResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      };

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
        mountRef.current?.removeChild(renderer.domElement);
      };
    }
  }, []);

  return <div ref={mountRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden' }} />;
};

export default ThreeBackground;
