import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';

const About: React.FC = () => {
  return (
    <Box p={8} maxW="lg" mx="auto" bg="background" borderRadius="md" boxShadow="md" color="text">
      <Heading as="h2" size="xl" color="primary" mb={6}>
        About Us
      </Heading>
      <VStack spacing={6} align="start">
        <Text fontSize="lg">
          At Vexatek, we blend creativity with technology to deliver innovative solutions that empower your business.
        </Text>
        <Text fontSize="lg">
          Our team of tech enthusiasts is dedicated to providing cutting-edge services with a touch of fun and a lot of passion.
        </Text>
        <Text fontSize="lg">
          We believe in the power of collaboration, creativity, and continuous improvement to drive success.
        </Text>
        <Text fontSize="lg">
          Join us on this exciting journey as we explore new frontiers in technology, making your business dreams a reality.
        </Text>
      </VStack>
    </Box>
  );
};

export default About;
