import React, { useState } from 'react';
import { Box, Heading, FormControl, FormLabel, Input, Textarea, Button, VStack, Text, FormErrorMessage } from '@chakra-ui/react';
import emailjs from 'emailjs-com';

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState({ name: '', email: '', message: '' });

  const validateForm = () => {
    let isValid = true;
    let errors = { name: '', email: '', message: '' };

    if (name.trim() === '') {
      errors.name = 'Name is required';
      isValid = false;
    }

    if (email.trim() === '') {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
      isValid = false;
    }

    if (message.trim() === '') {
      errors.message = 'Message is required';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const templateParams = {
      name,
      email,
      message,
    };

    emailjs.send('service_va44ssy', 'template_da1ay44', templateParams, 'NRIPvfnBogNznesDO')
      .then((response) => {
        setStatus('Message sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      }, (error) => {
        setStatus('Failed to send message. Please try again.');
      });
  };

  return (
    <Box p={8} maxW="md" mx="auto" bg="background" borderRadius="md" boxShadow="md" color="text">
      <Heading as="h2" size="xl" color="primary" mb={6}>
        Contact Us
      </Heading>
      <VStack as="form" spacing={4} align="stretch" onSubmit={handleSubmit}>
        <FormControl id="name" isInvalid={!!errors.name}>
          <FormLabel color="text">Name</FormLabel>
          <Input 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            placeholder="Your Name" 
            bg="white" 
            color="gray.800" 
          />
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>
        <FormControl id="email" isInvalid={!!errors.email}>
          <FormLabel color="text">Email</FormLabel>
          <Input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Your Email" 
            bg="white" 
            color="gray.800" 
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>
        <FormControl id="message" isInvalid={!!errors.message}>
          <FormLabel color="text">Message</FormLabel>
          <Textarea 
            value={message} 
            onChange={(e) => setMessage(e.target.value)} 
            placeholder="Your Message" 
            bg="white" 
            color="gray.800" 
          />
          <FormErrorMessage>{errors.message}</FormErrorMessage>
        </FormControl>
        <Button colorScheme="teal" type="submit">
          Send Message
        </Button>
        {status && <Text mt={4}>{status}</Text>}
      </VStack>
    </Box>
  );
};

export default Contact;
