import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Horizontal Rectangles aligned to the Right */}
      <rect x="0" y="85" width="100" height="15" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      <rect x="15" y="70" width="85" height="15" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      <rect x="30" y="55" width="70" height="15" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      <rect x="45" y="40" width="55" height="15" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      <rect x="60" y="25" width="40" height="15" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      <rect x="75" y="10" width="25" height="15" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      
      {/* Vertical Rectangles aligned to the Top */}
      <rect x="0" y="0" width="15" height="85" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      <rect x="15" y="0" width="15" height="70" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      <rect x="30" y="0" width="15" height="55" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      <rect x="45" y="0" width="15" height="40" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      <rect x="60" y="0" width="15" height="25" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      <rect x="75" y="0" width="15" height="10" stroke="#E2E8F0" strokeWidth="3" fill="none" />
      
      {/* Smallest Squares */}
      <rect x="80" y="0" width="10" height="10" stroke="#E2E8F0" strokeWidth="0" fill="none" />
      <rect x="90" y="0" width="10" height="10" stroke="#E2E8F0" strokeWidth="3" fill="none" />
    </svg>
  );
};

export default Logo;
